// import { createAction } from 'redux-act';
//////////////////////////////////////////////////

// USER NOTIFICATION
// export const socketUserErrorConnected = createAction('socketUserErrorConnected');
// export const updateNotificationsStore = createAction('updateNotificationsStore');
// export const socketSendMessageToStore = createAction('socketSendMessageToStore');
// export const socketSendMessageRequest = createAction('socketSendMessageRequest');
// export const socketUserErrorConnectRequest = createAction('socketUserErrorConnectRequest');
// export const socketUserErrorReconnectRequest = createAction('socketUserErrorReconnectRequest');
// export const socketUserNotificationConnected = createAction('socketUserNotificationConnected');
// export const socketUserErrorDisconnectRequest = createAction('socketUserErrorDisconnectRequest');
// export const socketUserErrorFromCarrierConnected = createAction('socketUserErrorFromCarrierConnected');
// export const socketUserNotificationConnectRequest = createAction('socketUserNotificationConnectRequest');
// export const socketUserNotificationReconnectRequest = createAction('socketUserNotificationReconnectRequest');
// export const socketUserNotificationDisconnectRequest = createAction('socketUserNotificationDisconnectRequest');
// export const socketUserErrorFromCarrierConnectRequest = createAction('socketUserErrorFromCarrierConnectRequest');
// export const socketUserErrorFromCarrierReconnectRequest = createAction('socketUserErrorFromCarrierReconnectRequest');
// export const socketUserErrorFromCarrierDisconnectRequest = createAction('socketUserErrorFromCarrierDisconnectRequest');
// USER MASS ACTION
// export const socketUserMassActionErrorConnected = createAction('socketUserMassActionErrorConnected');
// export const socketUserMassActionErrorConnectRequest = createAction('socketUserMassActionErrorConnectRequest');
// export const socketUserMassActionErrorReconnectRequest = createAction('socketUserMassActionErrorReconnectRequest');
// export const socketUserMassActionErrorDisconnectRequest = createAction('socketUserMassActionErrorDisconnectRequest');
// USER DOCUMENT GENERATED
// export const socketRouteDocumentGeneratedRequest = createAction('socketRouteDocumentGeneratedRequest');
// export const socketRouteDocumentGeneratedReceived = createAction('socketRouteDocumentGeneratedReceived');
// export const socketRouteDocumentGeneratedConnected = createAction('socketRouteDocumentGeneratedConnected');
// export const socketRouteDocumentGeneratedReconnectRequest =
//   createAction('socketRouteDocumentGeneratedReconnectRequest');
// export const socketRouteDocumentGeneratedDisconnectRequest =
//   createAction('socketRouteDocumentGeneratedDisconnectRequest');
// USER DOCUMENT GENERATED
// export const socketUserDocumentGeneratedRequest = createAction('socketUserDocumentGeneratedRequest');
// export const socketUserDocumentGeneratedReceived = createAction('socketUserDocumentGeneratedReceived');
// export const socketUserDocumentGeneratedConnected = createAction('socketUserDocumentGeneratedConnected');
// export const socketUserDocumentGeneratedReceivedSuccess = createAction('socketUserDocumentGeneratedReceivedSuccess');
// export const socketUserDocumentGeneratedReconnectRequest = createAction('socketUserDocumentGeneratedReconnectRequest');
// export const socketUserDocumentGeneratedDisconnectRequest =
//   createAction('socketUserDocumentGeneratedDisconnectRequest');
// CARRIER DOCUMENT GENERATED
// export const socketCarrierDocumentGeneratedRequest = createAction('socketCarrierDocumentGeneratedRequest');
// export const socketCarrierDocumentGeneratedReceived = createAction('socketCarrierDocumentGeneratedReceived');
// export const socketCarrierDocumentGeneratedConnected = createAction('socketCarrierDocumentGeneratedConnected');
// export const socketCarrierDocumentGeneratedReconnectRequest =
//   createAction('socketCarrierDocumentGeneratedReconnectRequest');
// export const socketCarrierDocumentGeneratedDisconnectRequest =
//   createAction('socketCarrierDocumentGeneratedDisconnectRequest');
// BRANCH DOCUMENT GENERATED
// export const socketBranchDocumentGeneratedRequest = createAction('socketBranchDocumentGeneratedRequest');
// export const socketBranchDocumentGeneratedReceived = createAction('socketBranchDocumentGeneratedReceived');
// export const socketBranchDocumentGeneratedConnected = createAction('socketBranchDocumentGeneratedConnected');
// export const socketBranchDocumentGeneratedReconnectRequest =
//   createAction('socketBranchDocumentGeneratedReconnectRequest');
// export const socketBranchDocumentGeneratedDisconnectRequest =
//   createAction('socketBranchDocumentGeneratedDisconnectRequest');
// FLEET DOCUMENT GENERATED
// export const socketFleetDocumentGeneratedRequest = createAction('socketFleetDocumentGeneratedRequest');
// export const socketFleetDocumentGeneratedReceived = createAction('socketFleetDocumentGeneratedReceived');
// export const socketFleetDocumentGeneratedConnected = createAction('socketFleetDocumentGeneratedConnected');
// export const socketFleetDocumentGeneratedReconnectRequest =
//   createAction('socketFleetDocumentGeneratedReconnectRequest');
// export const socketFleetDocumentGeneratedDisconnectRequest =
//   createAction('socketFleetDocumentGeneratedDisconnectRequest');
// STATISTIC DOCUMENT GENERATED
// export const socketStatisticDocumentGeneratedRequest = createAction('socketStatisticDocumentGeneratedRequest');
// export const socketStatisticDocumentGeneratedReceived = createAction('socketStatisticDocumentGeneratedReceived');
// export const socketStatisticDocumentGeneratedConnected = createAction('socketStatisticDocumentGeneratedConnected');
// export const socketStatisticDocumentGeneratedReconnectRequest =
//   createAction('socketStatisticDocumentGeneratedReconnectRequest');
// export const socketStatisticDocumentGeneratedDisconnectRequest =
//   createAction('socketStatisticDocumentGeneratedDisconnectRequest');
// TEMPLATES DOCUMENT GENERATED
// export const socketTemplatesDocumentGeneratedRequest = createAction('socketTemplatesDocumentGeneratedRequest');
// export const socketTemplatesDocumentGeneratedReceived = createAction('socketTemplatesDocumentGeneratedReceived');
// export const socketTemplatesDocumentGeneratedConnected = createAction('socketTemplatesDocumentGeneratedConnected');
// export const socketTemplatesDocumentGeneratedReconnectRequest =
//   createAction('socketTemplatesDocumentGeneratedReconnectRequest');
// export const socketTemplatesDocumentGeneratedDisconnectRequest =
//   createAction('socketTemplatesDocumentGeneratedDisconnectRequest');
// IFTA DOCUMENT GENERATED
// export const socketIftaDocumentGeneratedRequest = createAction('socketIftaDocumentGeneratedRequest');
// export const socketIftaDocumentGeneratedReceived = createAction('socketIftaDocumentGeneratedReceived');
// export const socketIftaDocumentGeneratedConnected = createAction('socketIftaDocumentGeneratedConnected');
// export const socketIftaDocumentGeneratedReconnectRequest = createAction('socketIftaDocumentGeneratedReconnectRequest');
// export const socketIftaDocumentGeneratedDisconnectRequest =
//   createAction('socketIftaDocumentGeneratedDisconnectRequest');
// TODO: remove after testing sockets-v2
// LOAD BOARD ALARMS
// export const socketLBStateReceived = createAction('socketLBStateReceived');
// export const socketLBConnectRequest = createAction('socketLBConnectRequest');
// export const socketLBResultReceived = createAction('socketLBResultReceived');
// export const socketLBStateConnected = createAction('socketLBStateConnected');
// export const socketLBResultConnected = createAction('socketLBResultConnected');
// export const socketFilterStateReceived = createAction('socketFilterStateReceived');
// export const socketLBConfigStateReceived = createAction('socketLBConfigStateReceived');
// export const socketLBStateReconnectRequest = createAction('socketLBStateReconnectRequest');
// export const socketLBResultReconnectRequest = createAction('socketLBResultReconnectRequest');
// export const socketLBStateDisconnectRequest = createAction('socketLBStateDisconnectRequest');
// export const socketLBResultDisconnectRequest = createAction('socketLBResultDisconnectRequest');
// export const socketPostedShipmentStateReceived = createAction('socketPostedShipmentStateReceived');
// export const socketBookedShipmentStateReceived = createAction('socketBookedShipmentStateReceived');
// TODO: end of remove
// CARRIER PORTAL
// export const socketCarrierPortalReceived = createAction('socketCarrierPortalReceived');
// export const socketCarrierPortalConnected = createAction('socketCarrierPortalConnected');
// export const socketCarrierPortalConnectRequest = createAction('socketCarrierPortalConnectRequest');
// export const socketCarrierPortalReconnectRequest = createAction('socketCarrierPortalReconnectRequest');
// export const socketCarrierPortalDisconnectRequest = createAction('socketCarrierPortalDisconnectRequest');
// TEL
// TODO: remove after testing sockets-v2
// export const socketTelConnected = createAction('socketTelConnected');
// export const socketTelRateReceived = createAction('socketTelRateReceived');
// export const socketTelConnectRequest = createAction('socketTelConnectRequest');
// export const socketTelWarningReceived = createAction('socketTelWarningReceived');
// export const socketTelReconnectRequest = createAction('socketTelReconnectRequest');
// export const socketTelDocumentReceived = createAction('socketTelDocumentReceived');
// export const socketTelDisconnectRequest = createAction('socketTelDisconnectRequest');
// export const socketTelLoadStatusReceived = createAction('socketTelLoadStatusReceived');
// export const socketTelRateStatusReceived = createAction('socketTelRateStatusReceived');
// export const socketCloStatusToTelReceived = createAction('socketCloStatusToTelReceived');
// export const socketTelChatMessageReceived = createAction('socketTelChatMessageReceived');
// export const socketTelEventStatusReceived = createAction('socketTelEventStatusReceived');
// export const socketTelStatusMessageReceived = createAction('socketTelStatusMessageReceived');
// export const socketTelCostAllocationsReceived = createAction('socketTelCostAllocationsReceived');
// IMPORT
// export const socketImportConnected = createAction('socketImportConnected');
// export const saveImportListSuccess = createAction('saveImportListSuccess');
// export const socketImportConnectRequest = createAction('socketImportConnectRequest');
// export const socketImportReconnectRequest = createAction('socketImportReconnectRequest');
// export const socketImportDisconnectRequest = createAction('socketImportDisconnectRequest');
// AVAILABLE DRIVERS
// export const socketAvailableDriversConnected = createAction('socketAvailableDriversConnected');
// export const socketAvailableDriversNoteReceived = createAction('socketAvailableDriversNoteReceived');
// export const socketAvailableDriversStatusReceived = createAction('socketAvailableDriversStatusReceived');
// export const socketAvailableDriversConnectRequest = createAction('socketAvailableDriversConnectRequest');
// export const socketAvailableDriversReconnectRequest = createAction('socketAvailableDriversReconnectRequest');
// export const socketAvailableDriversLocationReceived = createAction('socketAvailableDriversLocationReceived');
// export const socketAvailableDriversDisconnectRequest = createAction('socketAvailableDriversDisconnectRequest');
// export const socketAvailableDriversReservationReceived = createAction('socketAvailableDriversReservationReceived');
// all drivers
// export const socketAllDriversConnected = createAction('socketAllDriversConnected');
// export const socketAllDriversConnectRequest = createAction('socketAllDriversConnectRequest');
// export const socketAllDriversReconnectRequest = createAction('socketAllDriversReconnectRequest');
// export const socketAllDriversDisconnectRequest = createAction('socketAllDriversDisconnectRequest');
// DRIVER CARDS
// export const socketDriverCardsTelReceived = createAction('socketDriverCardsTelReceived');
// export const socketDriverCardsTelConnected = createAction('socketDriverCardsTelConnected');
// export const socketDriverCardsTelConnectRequest = createAction('socketDriverCardsTelConnectRequest');
// export const socketDriverCardsTelReconnectRequest = createAction('socketDriverCardsTelReconnectRequest');
// export const socketDriverCardsTelDisconnectRequest = createAction('socketDriverCardsTelDisconnectRequest');
