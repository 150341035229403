import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../components/edit-report/helpers';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { getItemListRequest as getFleetServiceIssueListRequest } from '../fleet-list/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportSagas } from '../../report-common';
// sagas
import { visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature work-order
import * as A from './actions';
import {
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectPageVisited,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
  makeSelectFleetProfileSettings,
} from './selectors';
//////////////////////////////////////////////////

const workOrderEndpoints = {
  list: endpointsMap.workOrderList,
  [GC.FIELD_TRUCK]: {
    create: endpointsMap.truckWordOrder,
    list: endpointsMap.truckWordOrderList,
    invoice: endpointsMap.truckWordOrderInvoice,
    invoiceList: endpointsMap.truckWordOrderInvoiceList,
    remove: endpointsMap.getCurrentTruckWordOrderEndpoint,
    changeStatus: endpointsMap.getTruckWordOrderStatusEndpoint,
    currentInvoice: endpointsMap.getTruckWorkOrderInvoiceEndpoint,
  },
  [GC.FIELD_TRAILER]: {
    create: endpointsMap.trailerWordOrder,
    list: endpointsMap.trailerWordOrderList,
    invoice: endpointsMap.trailerWordOrderInvoice,
    invoiceList: endpointsMap.trailerWordOrderInvoiceList,
    remove: endpointsMap.getCurrentTrailerWordOrderEndpoint,
    changeStatus: endpointsMap.getTrailerWordOrderStatusEndpoint,
    currentInvoice: endpointsMap.getTrailerWorkOrderInvoiceEndpoint,
  },
};

function* handleGetItemListSaga({ payload }: boolean) {
  try {
    if (G.isTrue(G.getPropFromObject('openLoader', payload))) {
      yield put(openLoader());
    }

    yield put(A.setListLoading(true));

    const reportParams = yield select(makeSelectUsedReport());
    const availableReports = yield select(makeSelectAvailableReports());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.propEq(`${GC.WORK_ORDER_REPORT}Default`, GC.FIELD_GUID, reportParams),
    )) {
      yield put(A.setListLoading(false));

      return yield put(closeLoader());
    }

    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const {
      fleetProfileType,
      fleetProfileGuid,
      fleetProfileGuidType,
    } = yield select(makeSelectFleetProfileSettings());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const guids = R.pathOr(null, ['guids'], payload);

    const { limit, offset } = pagination;

    const systemFields = [
      { name: GC.FIELD_BRANCH_GUID, freezed: false, sequence: 100, reference: false },
      { name: GC.GRC.FLEET_TRUCK_GUID, freezed: false, sequence: 101, reference: false },
      { name: GC.GRC.FLEET_TRAILER_GUID, freezed: false, sequence: 102, reference: false },
      { name: GC.GRC.FLEET_TRUCK_UNIT_ID, freezed: false, sequence: 103, reference: false },
      { name: GC.GRC.FLEET_TRAILER_UNIT_ID, freezed: false, sequence: 104, reference: false },
      { name: GC.GRC.FLEET_SERVICE_ISSUES_GUID, freezed: false, sequence: 105, reference: false, collection: true },
    ];

    const reqBody = {
      guids,
      limit,
      offset,
      orderFields,
      systemFields,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      fields: G.getOrElse(reportParams, 'fields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const options = {
      data: G.setSearchCriteria({ reqBody, filterParams: newFilterParams }),
      params: G.ifElse(
        G.isNilOrEmpty(fleetProfileType),
        null,
        { [fleetProfileGuidType]: fleetProfileGuid },
      ),
    };

    const endpoint = R.pathOr(
      G.getPropFromObject('list', workOrderEndpoints),
      [fleetProfileType, 'list'], workOrderEndpoints,
    );

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({ data, guids }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
    }

    yield put(A.setListLoading(false));
    yield put(closeLoader());
  } catch (err) {
    yield put(A.setListLoading(false));
    yield put(closeLoader());

    yield call(G.handleException, err, 'handleGetItemListSaga exception');
  }
}

const createUpdateReportSuccessCallback = (data: Object) => G.getReportSortedBySeqFreez(data);

const {
  handleAvailableReportsRequest,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleChangeDefaultReportSaga,
} = getReportSagas(GC.WORK_ORDER_REPORT, A, handleGetItemListSaga, { createUpdateReportSuccessCallback });

// item
function* handleCreateOrUpdateWorkOrderRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { values, entityType, saveAndClose, successCallback, openedFromIssue } = payload;

    const endpoint = R.path([entityType, 'create'], workOrderEndpoints);
    const method = G.ifElse(G.isNilOrEmpty(G.getPropFromObject(GC.FIELD_VERSION, values)), 'post', 'put');

    const res = yield call(sendRequest, method, endpoint, { data: values });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      if (G.isTrue(saveAndClose)) yield put(closeModal());

      G.callFunctionWithArgs(successCallback, { data });

      if (G.isTrue(openedFromIssue)) {
        const issueGuids = G.getPropFromObject(GC.FIELD_ISSUE_GUIDS, values);

        if (G.isNotNilAndNotEmpty(issueGuids)) {
          yield put(getFleetServiceIssueListRequest({ guids: issueGuids }));
        }
      } else {
        yield put(A.getItemListRequest({ guids: R.of(Array, G.getGuidFromObject(data)) }));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateOrUpdateWorkOrderRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleCreateOrUpdateWorkOrderRequestSaga exception');
  }
}

function* handleDeleteItemSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { guid, entityType } = payload;

    const endpoint = R.path([entityType, 'remove'], workOrderEndpoints)(guid);

    const res = yield call(sendRequest, 'delete', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'toastr:success:204');

      yield put(closeModal());
      yield put(A.deleteItemSuccess(guid));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteItemSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeleteItemSaga exception');
  }
}

function* handleChangeWorkOrderStatusRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { values, entityType, successCallback } = payload;

    const endpoint = R.path([entityType, 'changeStatus'], workOrderEndpoints)(G.getGuidFromObject(values));

    const res = yield call(sendRequest, 'put', endpoint, { params: values });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      G.callFunctionWithArgs(successCallback, data);

      yield put(closeModal());
      yield put(A.getItemListRequest({ guids: R.of(Array, G.getGuidFromObject(data)) }));

      yield call(G.showToastrMessage, 'success', 'messages:success:save');
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeWorkOrderStatusRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleChangeWorkOrderStatusRequestSaga exception');
  }
}

// invoice
function* handleGetWorkOrderInvoicesRequestSaga({ payload }: Object) {
  try {
    const { guid, entityType, successCallback } = payload;

    const options = { params: { [GC.FIELD_WORK_ORDER_GUID]: guid } };
    const endpoint = R.path([entityType, 'invoiceList'], workOrderEndpoints);

    const res = yield call(sendRequest, 'get', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      G.callFunctionWithArgs(successCallback, R.indexBy(R.prop(GC.FIELD_GUID), data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetWorkOrderInvoicesRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetWorkOrderInvoicesRequestSaga exception');
  }
}

function* handleCreateOrUpdateWorkOrderInvoiceRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { values, entityType, isEditMode, successCallback } = payload;

    const method = G.ifElse(isEditMode, 'put', 'post');
    const endpoint = R.path([entityType, 'invoice'], workOrderEndpoints);

    const res = yield call(sendRequest, method, endpoint, { data: values });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      G.callFunctionWithArgs(successCallback, { data, values });

      yield put(closeModal());
      yield put(A.getItemListRequest({ guids: R.of(Array, G.getPropFromObject(GC.FIELD_WORK_ORDER_GUID, data)) }));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateOrUpdateWorkOrderInvoiceRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateOrUpdateWorkOrderInvoiceRequestSaga exception');
  }
}

function* handleDeleteWorkOrderInvoiceRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { guid, entityType, workOrderGuid, successCallback } = payload;

    const endpoint = R.path([entityType, 'currentInvoice'], workOrderEndpoints)(guid);

    const res = yield call(sendRequest, 'delete', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.callFunction(successCallback);

      yield put(A.getItemListRequest({ guids: R.of(Array, workOrderGuid) }));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteWorkOrderInvoiceRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteWorkOrderInvoiceRequestSaga exception');
  }
}

// visit page
function* handleVisitWorkOrderListPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_WORK_ORDER_LIST_PAGE);

    yield put(openLoader());

    const pageVisited = yield select(makeSelectPageVisited());

    if (G.isTrue(pageVisited)) {
      yield put(A.setInitialStateOmitReport());
    } else {
      yield put(A.setInitialState());
    }

    yield put(A.setIgnorePromptStatus(false));
    yield put(A.setReportPending());

    yield call(handleAvailableReportsRequest, { payload, notSetUsedReport: pageVisited });
    yield call(handleGetItemListSaga, { payload: { openLoader: true } });

    yield put(closeLoader());

    break;
  }
}

function* handlevisitWorkOrdersOnFleetPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    const { fleetProfileType, fleetProfileGuid, fleetProfileGuidType } = payload;

    yield put(openLoader());
    yield put(A.setInitialState());
    yield put(A.setFleetProfileSettings({ fleetProfileType, fleetProfileGuid, fleetProfileGuidType }));
    yield put(A.setReportPending());

    yield call(handleAvailableReportsRequest, { payload, notSetUsedReport: false });

    yield put(A.getItemListRequest());
    yield put(closeLoader());

    break;
  }
}

function* workOrderWatcherSaga() {
  // visit page
  yield takeLatest(GC.VISIT_WORK_ORDER_LIST_PAGE, handleVisitWorkOrderListPageSaga);
  yield takeLatest(A.visitWorkOrdersOnFleetPage, handlevisitWorkOrdersOnFleetPageSaga);
  // report
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  // item
  yield takeLatest(A.deleteItemRequest, handleDeleteItemSaga);
  yield takeLatest(A.changeWorkOrderStatusRequest, handleChangeWorkOrderStatusRequestSaga);
  yield takeLatest(A.createOrUpdateWorkOrderRequest, handleCreateOrUpdateWorkOrderRequestSaga);
  // invoice
  yield takeLatest(A.getWorkOrderInvoicesRequest, handleGetWorkOrderInvoicesRequestSaga);
  yield takeLatest(A.deleteWorkOrderInvoiceRequest, handleDeleteWorkOrderInvoiceRequestSaga);
  yield takeLatest(A.createOrUpdateWorkOrderInvoiceRequest, handleCreateOrUpdateWorkOrderInvoiceRequestSaga);
}

export default workOrderWatcherSaga;
