import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import React, { useCallback } from 'react';
// helpers/constants
import * as G from '../../../../helpers';
// ui
import { Box, Flex, Text } from '../../../../ui';
// feature template-inspection
import {
  updateSectionFields,
  setActiveVehicleSectionComponent,
} from '../actions';
import VehicleComponent from './vehicle-component';
import { CheckboxComponent } from './option-components';
import { ActiveWrapper, VehicleImageBox, VehicleComponentCircle } from '../ui';
import vehicleTypesConfig from '../settings/vehicle-configs/vehicle-types-config';
//////////////////////////////////////////////////

const warningColor = G.getTheme('colors.warning');

const VehicleTypeComponent = ({
  section,
  vehicleComponent,
}: Object) => {
  const { name } = vehicleComponent;
  const { activeVehicleComponentType, components } = section;

  const vehicleTypeComponent = R.find(R.propEq(activeVehicleComponentType, 'vehicleComponentType'), components);

  return (
    <Flex gap={20} width='100%' flexDirection='column' alignItems='flex-start'>
      <Text fontSize={18} fontWeight='bold'>{name}</Text>
      { R.isNil(vehicleTypeComponent) && (
        <Text color={warningColor}>{G.getWindowLocale(
          'titles:inspection-add-component',
          'Please add any component from the component panel',
        )}</Text>
      )}
      { G.isNotNilAndNotEmpty(vehicleTypeComponent) && (
        <VehicleComponent component={vehicleTypeComponent} />
      )}
    </Flex>
  );
};

const VehiclePartCircle = ({
  name,
  type,
  section,
  position,
  handleSelectVehiclePart,
  activeVehicleComponentType,
}: Object) => {
  const [x, y] = position;
  const { components } = section;

  const active = R.equals(type, activeVehicleComponentType);
  const vehicleTypeComponent = R.find(R.propEq(type, 'vehicleComponentType'), components);

  const error = R.or(
    G.isNotNilAndNotEmpty(R.prop('errorMessage', vehicleTypeComponent)),
    R.and(G.isNotNil(vehicleTypeComponent), G.isNilOrEmpty(R.prop('name', vehicleTypeComponent))),
  );

  const filled = R.and(G.isNotNil(vehicleTypeComponent), R.not(error));

  return (
    <VehicleComponentCircle
      top={y}
      left={x}
      name={name}
      error={error}
      active={active}
      filled={filled}
      onClick={() => handleSelectVehiclePart(type)}
    >
      <Text fontSize={15} fontWeight='bold'>i</Text>
    </VehicleComponentCircle>
  );
};

const VehicleSection = ({ section, isInactive }: Object) => {
  const dispatch = useDispatch();

  const { type, activeVehicleComponentType, photoRequired } = section;

  const sectionConfig = R.prop(type, vehicleTypesConfig);
  const SectionImage = R.prop('image', sectionConfig);
  const imageStyles = R.propOr({}, 'imageStyles', sectionConfig);
  const Marker = R.prop('marker', sectionConfig);
  const markerStyles = R.propOr({}, 'markerStyles', sectionConfig);
  const directionColumn = R.prop('directionColumn', sectionConfig);
  const flipImage = R.prop('flipImage', sectionConfig);
  const vehicleComponentTypes = R.prop('vehicleComponentTypes', sectionConfig);

  const togglePhotoRequired = useCallback(() => {
    dispatch(updateSectionFields({
      ...section,
      photoRequired: R.not(photoRequired),
    }));
  }, [photoRequired]);

  const handleSelectVehiclePart = useCallback((activeVehicleComponentType: string) => {
    dispatch(setActiveVehicleSectionComponent({section, activeVehicleComponentType}));
  }, [section]);

  const selectedComponent = R.propOr({}, activeVehicleComponentType, vehicleComponentTypes);

  const separator = `1px solid ${G.getTheme('colors.lightGrey')}`;

  return (
    <ActiveWrapper inactive={isInactive}>
      <Flex
        gap={30}
        width='100%'
        alignItems='flex-start'
        justifyContent='flex-start'
        {...(directionColumn && { flexDirection: 'column' })}
      >
        <Flex
          gap={10}
          flexDirection='column'
          alignItems='flex-start'
          justifyContent='flex-start'
        >
          <CheckboxComponent
            width='100%'
            name='photo-required'
            value={photoRequired}
            wrapperStyles={{ mt: 2, ml: 30 }}
            setCheckboxValue={togglePhotoRequired}
            label={G.getWindowLocale('titles:inspection-photo-required', 'Photo Required')}
          />
          <VehicleImageBox flip={flipImage} {...imageStyles}>
            { G.isNotNilAndNotEmpty(Marker) && (
              <Box position='absolute' {...markerStyles}>
                <Marker />
              </Box>
            )}
            <SectionImage />
            <Box position='absolute' margin='auto' width='100%' height='100%'>
              {
                R.map(([key, value]: Array) => {
                  return (
                    <VehiclePartCircle
                      {...value}
                      key={key}
                      type={key}
                      section={section}
                      handleSelectVehiclePart={handleSelectVehiclePart}
                      activeVehicleComponentType={activeVehicleComponentType}
                    />
                  );
                }, R.toPairs(vehicleComponentTypes))
              }
            </Box>
          </VehicleImageBox>
        </Flex>
        <Flex
          width='100%'
          alignItems='flex-start'
          {...(directionColumn && { borderTop: separator, pt: 15 })}
          {...(R.not(directionColumn) && { borderLeft: separator, pl: 15 })}
        >
          <VehicleTypeComponent section={section} vehicleComponent={selectedComponent} />
        </Flex>
      </Flex>
    </ActiveWrapper>
  );
};

export default VehicleSection;
