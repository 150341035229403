import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature report-format
import { reportTypeLocaleMap } from './filter-props';
//////////////////////////////////////////////////

export const columnSettings = {
  [GC.FIELD_BRANCH_DOT_BRANCH_NAME]: {
    width: 200,
    name: 'titles:branch-name',
  },
  [GC.FIELD_NAME]: {
    width: 250,
    name: 'titles:name',
  },
  [GC.FIELD_TYPE]: {
    width: 350,
    name: 'titles:type',
    customComponent: ({ name }: Object) => G.getWindowLocale(R.path([name], reportTypeLocaleMap), name),
  },
  [GC.FIELD_OWNER]: {
    width: 150,
    name: 'titles:owner',
  },
  [GC.FIELD_PROMPT]: {
    width: 100,
    type: 'boolean',
    name: 'titles:prompt',
  },
  [GC.FIELD_DESCRIPTION]: {
    width: 300,
    name: 'titles:description',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:last-modified-date',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:created-date',
  },
};
