import * as R from 'ramda';
import { delay } from 'redux-saga';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
// TODO: remove after testing sockets-v2
// import { socketTemplatesDocumentGeneratedRequest } from '../../sockets/actions';
import { getAllAvailableRefTypesByScopeRequest } from '../../reference/actions';
import { setInitialRouteGuid, setEditTemplateRoute } from '../../dispatch-planner/actions';
import {
  checkReportFunction,
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../../components/edit-report/helpers';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// report-common
import { generateDefaultReport } from '../../../report-common';
// sagas
import { visitPageSaga } from '../../../sagas';
// utilities
import routesMap from '../../../utilities/routes';
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature template-report
import * as A from './actions';
import { getRequiredFieldsByReportType, getReferenceScopeByReportType } from './settings';
import {
  makeSelectListType,
  makeSelectReportType,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectGlobalFilterValue,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

const templateEndpoints = {
  [GC.ITEM_REPORT]: {
    template: endpointsMap.item,
    listUp: endpointsMap.listItemUp,
    listDown: endpointsMap.listItemDown,
    reference: endpointsMap.itemReference,
    listUpExport: endpointsMap.itemUpExportReport,
    activate: endpointsMap.getActivateItemEndpoint,
    listDownExport: endpointsMap.itemDownExportReport,
    deactivate: endpointsMap.getDeactivateItemEndpoint,
  },
  [GC.LOCATION_TEMPLATE_REPORT]: {
    template: endpointsMap.location,
    listUp: endpointsMap.listLocationsUp,
    listDown: endpointsMap.listLocationsDown,
    reference: endpointsMap.locationReference,
    listUpExport: endpointsMap.locationUpExportReport,
    activate: endpointsMap.getActivateLocationEndpoint,
    listDownExport: endpointsMap.locationDownExportReport,
    deactivate: endpointsMap.getDeactivateLocationEndpoint,
  },
  [GC.CONTACT_BOOK_REPORT]: {
    template: endpointsMap.contactBook,
    listUp: endpointsMap.contactBookListUp,
    listDown: endpointsMap.contactBookListDown,
    listUpExport: endpointsMap.contactBookListUpExport,
    listDownExport: endpointsMap.contactBookListDownExport,
  },
  [GC.CONTAINER_TEMPLATE_REPORT]: {
    template: endpointsMap.containerTemplate,
    listUp: endpointsMap.listContainerTemplateUp,
    listDown: endpointsMap.listContainerTemplateDown,
    listUpExport: endpointsMap.containerTemplateUpExportReport,
    listDownExport: endpointsMap.containerTemplateDownExportReport,
  },
  [GC.COMPENSATION_TEMPLATE_REPORT]: {
    template: endpointsMap.compensationTemplate,
    listUp: endpointsMap.compensationTemplateListUp,
    listDown: endpointsMap.compensationTemplateListDown,
    massDelete: endpointsMap.compensationTemplateMassDelete,
    listUpExport: endpointsMap.compensationTemplateListUpExport,
    listDownExport: endpointsMap.compensationTemplateListDownExport,
  },
  [GC.SHARED_ACCESSORIAL_REPORT]: {
    template: endpointsMap.sharedAccessorial,
    list: endpointsMap.sharedAccessorialList,
  },
  [GC.ROUTE_TEMPLATE_REPORT]: {
    template: endpointsMap.routeTemplate,
    list: endpointsMap.routeTemplateList,
    activate: endpointsMap.getActivateRouteTemplateEndpoint,
    deactivate: endpointsMap.getDeactivateRouteTemplateEndpoint,
  },
  [GC.CLO_TEMPLATE_REPORT]: {
    template: endpointsMap.cloTemplate,
    list: endpointsMap.cloTemplateList,
  },
};

export function* getItemListRequest() {
  try {
    yield put(A.setListLoading(true));
    const reportParams = yield select(makeSelectUsedReport());
    const availableReports = yield select(makeSelectAvailableReports());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.path(['defaultReport'], reportParams),
    )) return yield put(A.setListLoading(false));

    const listType = yield select(makeSelectListType());
    const pagination = yield select(makeSelectPagination());
    const reportType = yield select(makeSelectReportType());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const globalFilterValue = yield select(makeSelectGlobalFilterValue());
    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams, reportType);
    const fields = G.getOrElse(reportParams, 'fields', []);
    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );
    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );
    const reqBody = {
      ...pagination,
      fields,
      orderFields,
      globalFilterValue,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria, reportType),
    };

    if (G.isNotEmpty(reqBody.fields)) {
      const requiredFields = getRequiredFieldsByReportType(reportType);

      reqBody.fields = G.addRequiredFields(reqBody.fields, requiredFields);

      if (R.equals(reportType, GC.SHARED_ACCESSORIAL_REPORT)) {
        reqBody.fields = R.append(
          {
            sequence: 100,
            freezed: false,
            reference: false,
            collection: true,
            name: GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES,
          },
          reqBody.fields,
        );
      }
    }

    const options = {
      data: G.setSearchCriteria({ filterParams: newFilterParams, reqBody }),
    };
    const endpoint = R.path([reportType, listType], templateEndpoints);

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setListLoading(false));
      yield put(A.getItemListSuccess(data));
    } else {
      yield put(A.setListLoading(false));
      yield call(G.handleFailResponse, res, 'getItemListRequest fail');
    }
  } catch (error) {
    yield put(A.setListLoading(false));
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getItemListRequest exception');
  }
}

export function* getAvailableReportsRequest({ payload }: Object) {
  try {
    const { setUsedReport } = payload;

    const reportType = yield select(makeSelectReportType());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const params = {
      reportType,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };

    const res = yield call(sendRequest, 'get', endpointsMap.listReports, { params });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const reports = G.getReportsSortedBySeqFreez(data);
      const checkedReports = checkReportFunction(reports);
      yield put(A.setReports(checkedReports));

      if (R.not(R.prop('length', reports))) {
        return yield put(A.setUsedReport(generateDefaultReport(reportType)));
      }

      if (G.isTrue(setUsedReport)) {
        const defaultReport = G.findDefaultReport(checkedReports);
        const usedReport = R.or(defaultReport, generateDefaultReport(reportType));

        yield put(A.setUsedReport(usedReport));
      }
    } else {
      yield call(G.handleFailResponse, res, 'getAvailableReportsRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getAvailableReportsRequest exception');
  }
}

function* createReportRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, currentBranchGuid, payload),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.report, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setUsedReport(G.getReportSortedBySeqFreez(R.head(checkReportFunction(R.of(Array, data))))));
      yield put(A.getAvailableReportsRequest({ setUsedReport: false }));
      yield put(A.getItemListRequest());
    } else {
      yield call(G.handleFailResponse, res, 'createReportRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createReportRequest exception');
  }
}

function* updateReportRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'put', endpointsMap.report, { data: payload });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setUsedReport(G.getReportSortedBySeqFreez(R.head(checkReportFunction(R.of(Array, data))))));
      yield put(A.getAvailableReportsRequest({ setUsedReport: false }));
      yield put(A.getItemListRequest());
    } else {
      yield call(G.handleFailResponse, res, 'updateReportRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateReportRequest exception');
  }
}

export function* changeDefaultReportRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'put', endpointsMap.changeDefaultReport, { data: payload });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(getAvailableReportsRequest, { payload: { setUsedReport: true } });

      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest());
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeDefaultReportSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleChangeDefaultReportSaga exception');
  }
}

function* exportReportDataRequest({ payload }: Object) {
  try {
    const {
      name,
      fields,
      fileType,
      orderFields,
      searchCriteria,
    } = payload;

    yield put(openLoader({ showDimmer: true }));
    const listType = yield select(makeSelectListType());
    const reportType = yield select(makeSelectReportType());
    const filterParams = yield select(makeSelectFilterParams());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);
    const reqBody = {
      fields,
      orderFields,
      searchCriteria,
      [GC.FIELD_REPORT_NAME]: name,
      [GC.CURRENT_BRANCH]: branchGuid,
    };
    const params = { format: fileType };
    const sendData = G.setSearchCriteria({ filterParams: newFilterParams, reqBody });
    const options = { params, data: sendData, resType: 'arraybuffer' };
    const endpoint = R.path([reportType, `${listType}Export`], templateEndpoints);

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'exportReportDataRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'exportReportDataRequest exception');
  }
}
// report

// reference
function* createReferenceRequest({ payload }: Object) {
  try {
    const { values, selectedList } = payload;

    yield put(openLoader());
    const reportType = yield select(makeSelectReportType());
    const endpoint = R.path([reportType, 'reference'], templateEndpoints);
    const options = {
      data: R.assoc('primaryObjectGuids', selectedList, values),
    };

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest());
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createReferenceRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createReferenceRequest exception');
  }
}

// shared accessorials
function* restoreInheritedSharedAccessorialsRequest() {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'put', endpointsMap.restoreInheritedSharedAccessorial, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest());
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'restoreInheritedSharedAccessorialsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, 'restoreInheritedSharedAccessorialsRequest exception');
  }
}

// route template
function* handleCreateRouteFromTemplateSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { singleRoute, editTemplate, temporaryTemplate, shouldGoToRouteBuilder } = payload;

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, R.omit(['shouldGoToRouteBuilder'], payload)),
    };

    const endpoint = G.ifElse(singleRoute, endpointsMap.createRouteFromTemplate, endpointsMap.createRoutesFromTemplate);

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'toasts:success:create');

      if (G.isTrue(shouldGoToRouteBuilder)) {
        yield put(setInitialRouteGuid(data));

        if (G.isTrue(editTemplate)) {
          yield put(setEditTemplateRoute(true));
        }

        yield delay(1000);

        yield call(G.goToRoute, routesMap.routeBuilder);
      } else if (temporaryTemplate) {
        yield call(G.goToRoute, GC.ROUTE_PATH_TEMPLATES_ROUTE_LIST);
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateRouteFromTemplateSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateRouteFromTemplateSaga  exception');
  }
}

function* updateRouteTemplateRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = {
      data: payload,
    };

    const endpoint = endpointsMap.pureUpdateRouteTemplate;

    const res = yield call(sendRequest, 'put', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'toasts:success:create');
    } else {
      yield call(G.handleFailResponse, res, 'updateRouteTemplateRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateRouteTemplateRequest  exception');
  }
}

// item
function* createOrUpdateTemplateItemRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const reportType = yield select(makeSelectReportType());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const isUpdate = G.isNotNilAndNotEmpty(G.getPropFromObject(GC.FIELD_VERSION, payload));
    const method = G.ifElse(isUpdate, 'put', 'post');
    const endpoint = R.path([reportType, 'template'], templateEndpoints);

    const options = {
      data: R.assoc(GC.BRANCH_GUID, branchGuid, payload),
    };

    const res = yield call(sendRequest, method, endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrUpdateTemplateItemSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateTemplateItemRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrUpdateTemplateItemRequest exception');
  }
}

function* removeTemplateItemsRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = { data: payload };

    const reportType = yield select(makeSelectReportType());

    const endpoint = R.path(
      [reportType, G.ifElse(R.equals(reportType, GC.COMPENSATION_TEMPLATE_REPORT), 'massDelete', 'template')],
      templateEndpoints,
    );

    const res = yield call(sendRequest, 'delete', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status, data)) {
      yield put(closeModal());

      let guids = G.ifElse(G.isArray(payload), payload, R.of(Array, G.getGuidFromObject(payload)));

      if (R.and(R.equals(status, 202), G.isNotNilAndNotEmpty(data))) {
        guids = R.difference(guids, R.keys(data));
      }

      if (G.isNotNilAndNotEmpty(guids)) {
        yield call(G.showToastrMessage, 'success', 'messages:success:remove');

        yield put(A.removeTemplateItemsSuccess(guids));
      }
    } else {
      yield call(G.handleFailResponse, res, 'removeTemplateItemsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeTemplateItemsRequest exception');
  }
}

function* changeTemplateItemStatusRequest({ payload }: Object) {
  try {
    const { guid, active, callback } = payload;

    yield put(openLoader({ showDimmer: true }));
    const reportType = yield select(makeSelectReportType());
    const endpoint = G.ifElse(
      G.isFalse(active),
      R.path([reportType, 'activate'], templateEndpoints),
      R.path([reportType, 'deactivate'], templateEndpoints),
    )(guid);

    const res = yield call(sendRequest, 'put', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isFunction(callback)) {
        callback();
      } else {
        yield put(closeModal());
        yield put(A.changeTemplateItemStatusSuccess(guid));
        yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      }
    } else {
      yield call(G.handleFailResponse, res, 'changeTemplateItemStatusRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'changeTemplateItemStatusRequest exception');
  }
}

// compensation
function* createCompensationTemplateRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: R.assoc(GC.BRANCH_GUID, branchGuid, payload),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.compensationTemplate, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createCompensationTemplateRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createCompensationTemplateRequest exception');
  }
}

function* getCompensationTemplateListRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.compensationTemplateList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const { callback } = payload;

      G.callFunctionWithArgs(callback, data);
    } else {
      yield call(G.handleFailResponse, res, 'getCompensationTemplateListRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getCompensationTemplateListRequest exception');
  }
}

// configs
function* getBranchConfigsRequest({ payload }: Object) {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        names: R.join(', ', payload),
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getBranchConfigsSuccess(G.mapConfigValuesByName(data)));
    } else {
      yield call(G.handleFailResponse, res, 'getBranchConfigsRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getBranchConfigsRequest exception');
  }
}

// visit page
function* visitTemplateListPage({ payload }: Object) {
  while (true) { // eslint-disable-line
    const { reportType, checkVisitPage, listType = 'itemList' } = payload;

    yield call(visitPageSaga, payload, checkVisitPage);

    yield put(openLoader());

    const scope = getReferenceScopeByReportType(reportType);

    if (G.isNotNilAndNotEmpty(scope)) {
      yield put(getAllAvailableRefTypesByScopeRequest(scope));
    }

    yield put(A.setInitialState({ listType, reportType }));
    // TODO: remove after testing sockets-v2
    // yield put(socketTemplatesDocumentGeneratedRequest(G.getAmousCurrentUserFromWindow()));
    yield put(A.setReportPending());

    yield call(getAvailableReportsRequest, { payload: { setUsedReport: true } });

    yield put(A.getItemListRequest());

    yield put(closeLoader());

    break;
  }
}

function* templateReportWatcherSaga() {
  // visit pages
  yield takeLatest(GC.VISIT_ITEMS_LIST_PAGE, visitTemplateListPage);
  yield takeLatest(GC.VISIT_CONTACT_BOOK_PAGE, visitTemplateListPage);
  yield takeLatest(GC.VISIT_LOCATIONS_LIST_PAGE, visitTemplateListPage);
  yield takeLatest(GC.VISIT_DO_TEMPLATE_LIST_PAGE, visitTemplateListPage);
  yield takeLatest(GC.VISIT_SHARED_ACCESORIALS_PAGE, visitTemplateListPage);
  yield takeLatest(GC.VISIT_ROUTE_TEMPLATE_LIST_PAGE, visitTemplateListPage);
  yield takeLatest(GC.VISIT_CONTAINER_TEMPLATE_LIST_PAGE, visitTemplateListPage);
  yield takeLatest(GC.VISIT_COMPENSATION_TEMPLATE_LIST_PAGE, visitTemplateListPage);
  // report
  yield takeLatest(A.getItemListRequest, getItemListRequest);
  yield takeLatest(A.updateReportRequest, updateReportRequest);
  yield takeLatest(A.createReportRequest, createReportRequest);
  yield takeLatest(A.exportReportDataRequest, exportReportDataRequest);
  yield takeLatest(A.changeDefaultReportRequest, changeDefaultReportRequest);
  yield takeLatest(A.getAvailableReportsRequest, getAvailableReportsRequest);
  // route template
  yield takeLatest(A.updateRouteTemplateRequest, updateRouteTemplateRequest);
  yield takeLatest(A.createRouteFromTemplateRequest, handleCreateRouteFromTemplateSaga);
  // shared accessorials
  yield takeLatest(A.restoreInheritedSharedAccessorialsRequest, restoreInheritedSharedAccessorialsRequest);
  // item
  yield takeLatest(A.createReferenceRequest, createReferenceRequest);
  yield takeLatest(A.removeTemplateItemsRequest, removeTemplateItemsRequest);
  yield takeLatest(A.changeTemplateItemStatusRequest, changeTemplateItemStatusRequest);
  yield takeLatest(A.createOrUpdateTemplateItemRequest, createOrUpdateTemplateItemRequest);
  // compensation
  yield takeLatest(A.createCompensationTemplateRequest, createCompensationTemplateRequest);
  yield takeLatest(A.getCompensationTemplateListRequest, getCompensationTemplateListRequest);
  // configs
  yield takeLatest(A.getBranchConfigsRequest, getBranchConfigsRequest);
}

export default templateReportWatcherSaga;
