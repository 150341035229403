import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

const getRowTotal = (cost: number, quantity: number) => G.NaNToZero(G.mathRoundNumber(R.multiply(cost, quantity)));

const getSubTotal = (costs: Array) => R.reduce((acc: number, item: Object) => {
  const rowTotal = getRowTotal(
    R.pathOr(0, [GC.FIELD_COST], item),
    R.pathOr(1, [GC.FIELD_QUANTITY], item),
  );

  return R.add(acc, rowTotal);
}, 0, costs);

const getCostsTotal = ({ fees, parts, labors }: Array) =>
  R.sum([getSubTotal(fees), getSubTotal(parts), getSubTotal(labors)]);

const isSingleCurrency = (costs: Array) => R.compose(
  R.equals(1),
  R.length,
  R.uniqBy(R.prop(GC.FIELD_CURRENCY)),
)(costs);

const getInvoiceServiceIssues = (invoiceGuid: string, serviceIssueList: Array, onlyGuids: boolean = true) => {
  if (G.isAnyNilOrEmpty([invoiceGuid, serviceIssueList])) return [];

  return R.compose(
    R.when(() => G.isTrue(onlyGuids), R.map(R.prop(GC.FIELD_GUID))),
    R.filter(({ workOrderInvoiceGuid }: Object) => R.equals(invoiceGuid, workOrderInvoiceGuid)),
  )(serviceIssueList);
};

const getInvoiceServiceIssueOptions = (invoiceGuid: string, workOrder: Object, serviceIssueOptions: Array) => {
  if (G.isNilOrEmpty(serviceIssueOptions)) return [];

  return R.filter(
    ({ value, workOrderInvoiceGuid }: Object) => R.and(
      R.includes(value, R.pathOr([], [GC.FIELD_ISSUE_GUIDS], workOrder)),
      R.or(G.isNilOrEmpty(workOrderInvoiceGuid), R.equals(invoiceGuid, workOrderInvoiceGuid)),
    ),
    serviceIssueOptions,
  );
};

export {
  getRowTotal,
  getSubTotal,
  getCostsTotal,
  isSingleCurrency,
  getInvoiceServiceIssues,
  getInvoiceServiceIssueOptions,
};
