import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withState,
  withHandlers,
  renderNothing,
} from 'react-recompose';
// components
import { Table } from '../../components/table';
import { TitlePanel } from '../../components/title-panel';
import { PageActions } from '../../components/page-actions';
import { ConfirmComponent } from '../../components/confirm';
import { openModal, closeModal } from '../../components/modal/actions';
// icons
import * as I from '../../svgs';
// features
import { AuthWrapper } from '../permission';
import PC from '../permission/role-permission';
import BranchTreeComponent from '../branch/components/branch-tree';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withFixedPopover } from '../../hocs/with-fixed-popover';
import { withAsyncGetRoleGrantedBranchGuids } from '../../hocs';
// ui
import {
  IconWrapper,
  ListWrapper,
  ZOrderWrapper,
} from '../../ui';
// utilities
import routesMap from '../../utilities/routes';
// feature role
import { getSelectedList } from './helpers';
import { LIST_OWN, LIST_AVAILABLE } from './constants';
import RoleActions from './components/element-actions';
import { tableSettings } from './settings/table-settings';
import { columnSettings } from './settings/column-settings';
import {
  selectItem,
  setActiveList,
  removeRoleRequest,
  getRoleListRequest,
  grandRoleToChildRequest,
  ungrandRoleFromChildRequest,
} from './actions';
import {
  makeSelectActiveList,
  makeSelectTotalCount,
  makeSelectUsedReport,
  makeSelectRoleListChoosed,
  makeSelectRoleListLoading,
} from './selectors';
//////////////////////////////////////////////////

export const enhance = compose(
  withFixedPopover,
  withAsyncGetRoleGrantedBranchGuids,
  withState('selectedList', 'setSelectedList', []),
  withHandlers({
    handleGrandToSubcompanies: (props: Object) => async () => {
      const {
        itemList,
        openModal,
        closeModal,
        grandRoleToChildRequest,
        getGrantedEnterpriseGuids,
      } = props;

      const selectedList = getSelectedList(R.values(itemList));

      let initialSelectedList = R.pathOr([], ['parent', GC.FIELD_GRANTED_BRANCH_GUID], props);

      if (R.equals(R.length(selectedList), 1)) {
        const roleGuid = R.head(selectedList);

        initialSelectedList = await getGrantedEnterpriseGuids(roleGuid);
      }

      const modal = {
        p: '0px',
        component: (
          <BranchTreeComponent
            width={580}
            cursor='pointer'
            allowSelect={true}
            rootDisabled={true}
            closeAction={closeModal}
            initialSelectedList={initialSelectedList}
            handleMultipleSelect={(childBranchesGuids: Array) => {
              const data = {
                rolesGuids: selectedList,
                [GC.FIELD_CHILD_BRANCHES_GUIDS]: childBranchesGuids,
              };

              grandRoleToChildRequest(data);
              closeModal();
            }}
          />
        ),
        options: {
          width: 580,
          height: 'auto',
          minWidth: 'fit-content',
          title: G.getWindowLocale('titles:reference:gran-to-subcompanies', 'Grant to Subcompanies'),
        },
      };

      if (R.isEmpty(selectedList)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:roles:select-role', 'Please, select an Role!'),
        );
      }

      return openModal(modal);
    },
    handleUngrandFromSubcompanies: (props: Object) => async () => {
      const {
        itemList,
        openModal,
        closeModal,
        getGrantedEnterpriseGuids,
        ungrandRoleFromChildRequest,
      } = props;

      const selectedList = getSelectedList(R.values(itemList));

      let initialSelectedList = R.pathOr([], ['parent', GC.FIELD_GRANTED_BRANCH_GUID], props);

      if (R.equals(R.length(selectedList), 1)) {
        const roleGuid = R.head(selectedList);

        initialSelectedList = await getGrantedEnterpriseGuids(roleGuid);
      }

      const modal = {
        p: '0px',
        component: (
          <BranchTreeComponent
            width={580}
            cursor='pointer'
            allowSelect={true}
            rootDisabled={true}
            closeAction={closeModal}
            initialSelectedList={initialSelectedList}
            handleMultipleSelect={(childBranchesGuids: Array) => {
              const data = {
                rolesGuids: selectedList,
                [GC.FIELD_CHILD_BRANCHES_GUIDS]: childBranchesGuids,
              };

              ungrandRoleFromChildRequest(data);
              closeModal();
            }}
          />
        ),
        options: {
          width: 580,
          height: 'auto',
          minWidth: 'fit-content',
          title: G.getWindowLocale('titles:reference:ungrant-from-subcompanies', 'Ungrant from Subcompanies'),
        },
      };

      if (R.isEmpty(selectedList)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:roles:select-role', 'Please, select an Role!'),
        );
      }

      return openModal(modal);
    },
    handleSetActiveList: ({ setActiveList }: Object) => (type: string) => setActiveList(type),
    handleClickEditIcon: (props: Object) => (e: Object, roles: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        content: (
          <RoleActions
            roles={roles}
            openModal={props.openModal}
            closeModal={props.closeModal}
            closeFixedPopup={props.closeFixedPopup}
            removeRoleRequest={props.removeRoleRequest}
          />
        ),
      })
    ),
    handleEdit: () => (guid: string) => G.goToRoute(routesMap.getEditRolePage(guid)),
    handleRemove: (props: Object) => (guid: string, role: Object) => {
      const { openModal, closeModal, closeFixedPopup, removeRoleRequest } = props;

      closeFixedPopup();

      const confirmationContent = (
        <ConfirmComponent
          name={G.cutString(role.name, 20, true)}
          textLocale={G.getWindowLocale(
            'messages:before-remove',
            'Are you sure you want to remove')
          }
        />
      );

      const modal = {
        component: confirmationContent,
        options: {
          width: 600,
          height: 'max-content',
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                removeRoleRequest(R.of(Array, guid));
                closeModal();
              },
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  branch(
    (props: Object) => R.not(props.initialDataLoaded),
    renderNothing,
  ),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    usedReport,
    totalCount,
    selectItem,
    activeList,
    handleEdit,
    handleRemove,
    getRoleListRequest,
    handleClickEditIcon,
  } = props;

  const elementActionsComponent = (roles: Object) => (
    <AuthWrapper has={[PC.ROLES_WRITE]}>
      <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, roles)}>
        {I.threeDots()}
      </IconWrapper>
    </AuthWrapper>
  );

  const allChecked = G.isAllChecked(R.values(itemList));

  const actionButtons = [
    {
      iconName: 'pencil',
      action: handleEdit,
      permissions: [
        PC.ROLES_WRITE,
        PC.ROLE_LIUBANCHYK_SUPER_ADMIN,
      ],
    },
    {
      iconName: 'trash',
      action: handleRemove,
      permissions: [
        PC.ROLES_WRITE,
        PC.ROLE_LIUBANCHYK_SUPER_ADMIN,
      ],
    },
  ];

  const data = {
    loading,
    allChecked,
    totalCount,
    columnSettings,
    report: usedReport,
    hasSelectable: true,
    pagination: totalCount,
    onOptionClick: selectItem,
    itemList: R.values(itemList),
    handleLoadMoreEntities: getRoleListRequest,
    renderRightStickedComponent: elementActionsComponent,
    actionButtons: G.ifElse(R.equals(LIST_OWN, activeList), actionButtons),
    tableSettings: G.ifElse(
      R.equals(LIST_OWN, activeList),
      tableSettings,
      R.mergeRight(
        tableSettings,
        {
          allowEditBtn: false,
          allowSelectAll: false,
          allowSelectItems: false,
        },
      ),
    ),
  };

  return <Table {...data} />;
};

const multiswitchOptions = [
  {
    width: 100,
    value: LIST_OWN,
    name: G.getWindowLocale('titles:show-own', 'Show Own'),
  },
  {
    width: 100,
    value: LIST_AVAILABLE,
    name: G.getWindowLocale('titles:show-available', 'Show Available'),
  },
];

const RolesComponent = (props: Object) => {
  const {
    itemList,
    activeList,
    totalCount,
    handleSetActiveList,
    handleGrandToSubcompanies,
    handleUngrandFromSubcompanies,
  } = props;

  const whiteColor = G.getTheme('colors.white');

  const listActionsOpt = [
    {
      type: 'massAction',
      icon: I.grantTo(whiteColor),
      action: handleGrandToSubcompanies,
      text: G.getWindowLocale('titles:grand-to-child', 'Grand To Child'),
      permissions: [
        PC.ROLES_WRITE,
        PC.ROLE_LIUBANCHYK_SUPER_ADMIN,
      ],
    },
    {
      type: 'massAction',
      icon: I.grantTo(whiteColor),
      action: handleUngrandFromSubcompanies,
      text: G.getWindowLocale('titles:ungrand-from-child', 'Ungrand From Child'),
      permissions: [
        PC.ROLES_WRITE,
        PC.ROLE_LIUBANCHYK_SUPER_ADMIN,
      ],
    },
  ];

  return (
    <ListWrapper p='15' bgColor={G.getTheme('pages.layOutBgColor')}>
      <ZOrderWrapper zIndex='2'>
        <TitlePanel
          {...props}
          withCount={true}
          hideFilterInfo={true}
          hiddenRightFilterInfo={true}
          options={multiswitchOptions}
          onSwitch={handleSetActiveList}
          useMultiswitchComponent={true}
          title={G.getWindowLocale('titles:roles', 'Roles')}
          selectedOptionIndex={G.ifElse(R.equals(activeList, LIST_OWN), 0, 1)}
          totalCount={R.and(G.isNotNilAndNotEmpty(R.values(itemList)), totalCount)}
        />
      </ZOrderWrapper>
      <AuthWrapper has={[PC.ROLES_WRITE]}>
        <PageActions
          listActions={listActionsOpt}
          count={getSelectedList(R.values(itemList)).length}
          shadowColor={G.getTheme('createButton.shadowColor')}
          mainAction={{
            action: () => G.goToRoute(routesMap.createRolePage),
            text: G.getWindowLocale('titles:create-new-role', 'Create New Role'),
          }}
        />
      </AuthWrapper>
      <ZOrderWrapper zIndex='1'>
        {renderTable(props)}
      </ZOrderWrapper>
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  totalCount: makeSelectTotalCount(state),
  usedReport: makeSelectUsedReport(state),
  activeList: makeSelectActiveList(state),
  loading: makeSelectRoleListLoading(state),
  itemList: makeSelectRoleListChoosed(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  selectItem,
  setActiveList,
  removeRoleRequest,
  getRoleListRequest,
  grandRoleToChildRequest,
  ungrandRoleFromChildRequest,
})(enhance(RolesComponent));
