import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import React, { useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// forms
import { Error } from '../../../../forms/ui';
// helpers/constants
import * as G from '../../../../helpers';
// icons
import * as I from '../../../../svgs';
// ui
import { Flex, Badge } from '../../../../ui';
// feature template-inspection
import {
  updateSectionFields,
  updateComponentFields,
  deleteComponentFromSection,
} from '../actions';
import * as H from '../helpers';
import * as C from '../constants';
import IconInHeader from './component-icon';
import ComponentForm from './component-form';
import DeleteComponent from './delete-component';
import { ToggleButton, NameWithEdit } from './option-components';
import { SectionComponentUI, ActiveWrapper, MoveHandler } from '../ui';
import { componentsSettings } from '../settings/component-configs/components-settings';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.dark.blue');

const ComponentHeader = ({ component, dragDisabled, dragHandleProps }: Object) => {
  const dispatch = useDispatch();

  const type = R.prop('type', component);
  const expanded = R.prop('expanded', component);
  const componentName = R.prop('name', component);
  const nameStatus = R.prop('nameStatus', component);
  const errorMessage = R.prop('errorMessage', component);
  const componentProps = R.prop(type, componentsSettings);

  const { icon, name } = componentProps;

  const nameErrorMessage = G.getWindowLocale('titles:inspection-name-component-error', 'Enter component name');

  const setComponentName = useCallback((value: string) => {
    dispatch(updateComponentFields({
      ...component,
      name: value,
      nameStatus: G.ifElse(
        G.isNilOrEmpty(value),
        C.INSPECTION_NAME_STATUS_EMPTY,
        C.INSPECTION_NAME_STATUS_FILLED,
      ),
    }));
  }, [component]);

  const handleNameEdit = useCallback(() => {
    dispatch(updateComponentFields({
      ...component,
      nameStatus: C.INSPECTION_NAME_STATUS_EDIT,
    }));
  }, [component]);

  const handleComponentToggle = useCallback(() => {
    dispatch(updateComponentFields({
      ...component,
      expanded: R.not(expanded),
    }));
  }, [component]);

  const handleDeleteComponent = useCallback(() => dispatch(deleteComponentFromSection(component)), [component]);

  const removeComponentText = G.getWindowLocale(
    'messages:remove-inspection-component-text',
    'Are you sure you want delete this component?',
  );

  return (
    <Flex gap={4} width='100%'>
      <MoveHandler
        {...dragHandleProps}
        disabled={dragDisabled}
        onMouseDown={(e: Object) => e.currentTarget.focus()}
      >
        {I.moveIcon(iconColor, 16, 15)}
      </MoveHandler>
      <ToggleButton expanded={expanded} handleClick={handleComponentToggle} />
      { errorMessage && <Error mt={2} mr={8} fontSize={16} cursor='help' title={errorMessage}>*</Error> }
      <Flex width='100%' justifyContent='space-between'>
        <NameWithEdit
          focus={true}
          status={nameStatus}
          name={componentName}
          maxLength={C.MAX_NAME_LENGTH}
          errorMessage={nameErrorMessage}
          handleNameEdit={handleNameEdit}
          setInputValue={setComponentName}
        />
        <Flex gap='12px'>
          <IconInHeader component={component} />
          <Badge variant='grey'>
            <Flex gap='8px'>
              {icon}
              {name}
            </Flex>
          </Badge>
          <DeleteComponent
            confirmAction={handleDeleteComponent}
            confirmationText={removeComponentText}
            title={G.getWindowLocale('titles:inspection-delete-component', 'Delete Component')}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

const SectionComponent = ({ component, dragDisabled, dragHandleProps }: Object) => {
  const expanded = R.prop('expanded', component);

  return (
    <SectionComponentUI>
      <ComponentHeader dragDisabled={dragDisabled} component={component} dragHandleProps={dragHandleProps} />
      { expanded && <ComponentForm component={component} /> }
    </SectionComponentUI>
  );
};

const SectionComponents = ({ section, isInactive }: Object) => {
  const dispatch = useDispatch();

  const components = R.prop('components', section);
  const dragDisabled = R.equals(R.length(components), 1);

  const onDragEnd = useCallback(({ source, destination }: Object) => {
    const reordered = H.reorderList(components, R.prop('index', source), R.prop('index', destination));

    dispatch(updateSectionFields({
      ...section,
      components: reordered,
    }));
  }, [components]);

  return (
    <ActiveWrapper inactive={isInactive}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable' direction='vertical'>
          {(provided: Object) => (
            <Flex
              gap={8}
              width='100%'
              alignItems='normal'
              flexDirection='column'
              ref={provided.innerRef}
              {...provided.droppableProps}
              justifyContent='space-between'
            >
              {
                G.mapIndexed((component: Object, index: number) => {
                  const id = R.prop('id', component);

                  return (
                    <Draggable key={id} draggableId={id} index={index}>
                      {(provided: Object) => (
                        H.renderDraggable(provided.draggableProps, (
                          <div
                            width='100%'
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <SectionComponent
                              component={component}
                              dragDisabled={dragDisabled}
                              dragHandleProps={provided.dragHandleProps}
                            />
                          </div>
                        ))
                      )}
                    </Draggable>
                  );
                }, components)
              }
              {provided.placeholder}
            </Flex>
          )}
        </Droppable>
      </DragDropContext>
    </ActiveWrapper>
  );
};

export default SectionComponents;
