import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Flex, IconWrapper } from '../../../ui';
//////////////////////////////////////////////////

const darkGrayColor = G.getTheme('colors.darkGrey');
const darkBlueColor = G.getTheme('colors.dark.blue');

const RowActions = ({ handleEditInvoice, handleRemoveInvoice }: Object) => (
  <Flex mr={15} width={40} justifyContent='space-between'>
    <IconWrapper cursor='pointer' onClick={handleEditInvoice}>
      {I.pencil(darkBlueColor)}
    </IconWrapper>
    <IconWrapper cursor='pointer' onClick={handleRemoveInvoice}>
      {I.trash(darkBlueColor)}
    </IconWrapper>
  </Flex>
);

const InvoiceInfoPair = ({ text }: Object) => (
  <TextComponent
    mr={10}
    title={text}
    maxWidth={170}
    display='block'
    withEllipsis={true}
    color={darkGrayColor}
  >
    {text}
  </TextComponent>
);

const TotalInfo = ({ mr, costs, titleLocaleArr, currencySymbol }: Object) => {
  const total = G.calculateTotal(costs);
  const title = `${G.getWindowLocale(...titleLocaleArr)}: ${currencySymbol} ${total}`;

  return (
    <TextComponent
      mr={mr}
      title={title}
      maxWidth={120}
      display='block'
      withEllipsis={true}
    >
      {title}
    </TextComponent>
  );
};

const typeTitlesMap = {
  [GC.INVOICE_TYPE_COST]: ['titles:invoice', 'Invoice'],
  [GC.INVOICE_TYPE_ESTIMATE]: ['titles:estimate', 'Estimate'],
};

export const InvoiceInfo = (props: Object) => {
  const { invoice, serviceIssues, handleEditInvoice, handleRemoveInvoice } = props;

  const {
    type,
    fees,
    parts,
    total,
    labors,
    currency,
    invoiceDate,
    estimateDate,
    invoiceNumber,
    estimateNumber,
  } = invoice;

  const currencySymbol = G.getCurrencySymbol(currency);

  const isCostType = R.equals(type, GC.INVOICE_TYPE_COST);
  const date = G.ifElse(isCostType, invoiceDate, estimateDate);
  const number = G.ifElse(isCostType, invoiceNumber, estimateNumber);

  const invoiceDateText = `${G.getWindowLocale('titles:date', 'Date')}: ${date}`;
  const invoiceTotalText = `${G.getWindowLocale('titles:total', 'Total')}: ${currencySymbol} ${total}`;
  const issueIds = G.transformArrayOfObjectsToStringByPropName(serviceIssues, ', ', GC.FIELD_ISSUE_ID);
  const invoiceNumberText = `${G.getWindowLocale(...G.getPropFromObject(type, typeTitlesMap))} #: ${number}`;

  return (
    <Flex mb={15} alignItems='flex-start'>
      <RowActions handleEditInvoice={handleEditInvoice} handleRemoveInvoice={handleRemoveInvoice} />
      <Flex width='100%' alignItems='flex-start' flexDirection='column'>
        {
          G.isOneNotNilOrNotEmpty([date, number]) &&
          <Flex mb={10}>
            {G.isNotNilAndNotEmpty(number) && <InvoiceInfoPair text={invoiceNumberText} />}
            {G.isNotNilAndNotEmpty(date) && <InvoiceInfoPair text={invoiceDateText} />}
          </Flex>
        }
        {
          G.isNotNilAndNotEmpty(serviceIssues) &&
          <Flex mb={10}>
            <TextComponent
              maxWidth={380}
              display='block'
              title={issueIds}
              withEllipsis={true}
              color={darkGrayColor}
            >
              {`${G.getWindowLocale('titles:issues', 'Issues')}: ${issueIds}`}
            </TextComponent>
          </Flex>
        }
        <Flex mb={10}>
          <TotalInfo
            mr={10}
            costs={labors}
            currencySymbol={currencySymbol}
            titleLocaleArr={['titles:labor', 'Labor']}
          />
          <TotalInfo
            mr={10}
            costs={parts}
            currencySymbol={currencySymbol}
            titleLocaleArr={['titles:parts', 'Parts']}
          />
          <TotalInfo
            costs={fees}
            currencySymbol={currencySymbol}
            titleLocaleArr={['titles:fees', 'Fees']}
          />
        </Flex>
        <Flex pr={10} width='100%' justifyContent='flex-end'>
          <TextComponent
            maxWidth={300}
            display='block'
            fontWeight={700}
            withEllipsis={true}
            color={darkGrayColor}
            title={invoiceTotalText}
            textTransform='uppercase'
          >
            {invoiceTotalText}
          </TextComponent>
        </Flex>
      </Flex>
    </Flex>
  );
};
