import * as R from 'ramda';
import Dropzone from 'react-dropzone';
import parse from 'html-react-parser';
import { css } from 'styled-components';
import { Editor } from 'react-draft-wysiwyg';
import React, { memo, useRef, useState, Fragment, useEffect, useCallback, useLayoutEffect } from 'react';
// components
import { TabsMui } from '../../../components/tabs-mui';
import { TextComponent } from '../../../components/text';
import { FormFooter2 } from '../../../components/form-footer';
import { ConfirmComponent } from '../../../components/confirm';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// utilities
import endpointsMap from '../../../utilities/endpoints';
import { sendRequest, sendRequestWithQSParamsSerializer } from '../../../utilities/http';
// ui
import { Box, Span, Flex, EditorWrapper, scrollableContainerCss4px } from '../../../ui';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const blackColor = G.getTheme('colors.black');
const darkGreyColor = G.getTheme('colors.#7D828C');
const lightGreyColor = G.getTheme('colors.lightGrey');
const greyMatterhornColor = G.getTheme('colors.greyMatterhorn');

const wrapperStyles = {
  mb: 15,
  p: '7px',
  borderRadius: '4px',
  border: '1px solid',
  color: greyMatterhornColor,
  borderColor: lightGreyColor,
};

const renderConfirmationModal = ({ action, openModal, closeModal }: Object) => {
  const component = (
    <ConfirmComponent
      textLocale={G.getWindowLocale('messages:confirm-delete-entity', 'Are you sure you want to delete this entity?')}
    />
  );

  const modal = {
    component,
    options: {
      width: 400,
      controlButtons: [
        {
          type: 'button',
          name: G.getWindowLocale('actions:confirm', 'Confirm'),
          action: () => {
            closeModal();
            G.callFunction(action);
          },
        },
      ],
    },
  };

  openModal(modal);
};

const Document = memo((props: Object) => {
  const { document, openModal, closeModal, handleDocuments, handleDownloadOrPreviewDocument } = props;

  const { guid, createdBy, createdDate, documentFilename } = document;

  const formattedCreatedDate = G.convertDateTimeToConfigFormat(createdDate);

  return (
    <Box {...wrapperStyles} fontSize={12}>
      <Flex justifyContent='space-between'>
        <TextComponent withEllipsis={true} title={documentFilename} maxWidth='calc(100% - 80px)'>
          {documentFilename}
        </TextComponent>
        <Flex width={70} justifyContent='space-between'>
          <Box cursor='pointer' onClick={() => handleDownloadOrPreviewDocument({ guid })}>
            {I.eye(darkGreyColor)}
          </Box>
          <Box cursor='pointer' onClick={() => handleDownloadOrPreviewDocument({ guid, actionType: 'download' })}>
            {I.downloadDocument(darkGreyColor)}
          </Box>
          <Box
            cursor='pointer'
            onClick={() =>
              renderConfirmationModal({ openModal, closeModal, action: () => handleDocuments('delete', {}, guid) })
            }
          >
            {I.trash(darkGreyColor)}
          </Box>
        </Flex>
      </Flex>
      <Flex mt='5px'>
        <TextComponent
          mr='5px'
          title={createdBy}
          withEllipsis={true}
          color={darkGreyColor}
          maxWidth='calc(60% - 45px)'
        >
          {createdBy}
        </TextComponent>
        <TextComponent
          withEllipsis={true}
          color={darkGreyColor}
          maxWidth='calc(40% - 45px)'
          title={formattedCreatedDate}
        >
          {formattedCreatedDate}
        </TextComponent>
      </Flex>
    </Box>
  );
});

const AddDocument = ({ handleDocuments }: Object) => (
  <Dropzone
    className='drop-zone'
    onDrop={(files: Array) => handleDocuments(
      'post',
      { data: G.makeDataForMultipleDocuments({ [GC.FIELD_FILES]: files }) },
    )}
  >
    {({ getRootProps, getInputProps }: Object) => (
      <Flex
        {...getRootProps()}
        m={15}
        px={15}
        height={60}
        bg={whiteColor}
        cursor='pointer'
        borderRadius='4px'
        textAlign='center'
        border='1px dashed'
        wordBreak='break-word'
        justifyContent='center'
        width='calc(100% - 30px)'
        color={G.getTheme('colors.dark.blue')}
        borderColor={G.getTheme('listActions.newBorderColor')}
      >
        <input {...getInputProps()} />
        Drag and Drop or select files from your Computer
      </Flex>
    )}
  </Dropzone>
);

const Documents = (props: Object) => {
  const { openModal, closeModal, documents, openLoader, closeLoader, setActivities, primaryObjectGuid } = props;

  const handleDocuments = useCallback(async (method: string, options: Object, documentGuid: string) => {
    openLoader();

    const operations = {
      get: (prev: Object, data: Object) => data,
      post: (prev: Object, data: Object) => R.concat(data, prev),
      delete: (prev: Object) => {
        const index = R.findIndex(R.propEq(documentGuid, GC.FIELD_GUID), prev);

        return R.remove(index, 1, prev);
      },
    };

    const endpoints = {
      post: endpointsMap.workOrderDocument,
      get: endpointsMap.workOrderDocumentList,
      delete: endpointsMap.getWorkOrderDocumentEndpoint(documentGuid),
    };

    const res = await sendRequest(method, R.prop(method, endpoints), { ...options, params: { primaryObjectGuid } });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      setActivities((prev: Object) => {
        const documents = R.call(R.prop(method, operations), R.prop(GC.FIELD_DOCUMENTS, prev), data);

        return R.assoc(GC.FIELD_DOCUMENTS, documents, prev);
      });
    } else {
      G.handleFailResponseSimple(res, 'handleDocuments fail');
    }

    closeLoader();
  }, [primaryObjectGuid]);

  useEffect(() => {
    if (R.isNil(documents)) handleDocuments('get');
  }, [documents, handleDocuments]);

  const handleDownloadOrPreviewDocument = useCallback(async ({ guid, actionType }: Object) => {
    openLoader();

    const options = {
      params: { guid },
      resType: 'arraybuffer',
    };

    const endpoint = endpointsMap.getWorkOrderDocumentDownloadEndpoint(guid);

    const res = await sendRequestWithQSParamsSerializer('get', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      if (R.equals(actionType, 'download')) {
        G.saveFileFromResponse(res);
      } else {
        G.openFileInWindowFromArrayBufferResponse(res);
      }
    } else {
      G.handleFailResponseSimple(res);
    }

    closeLoader();
  }, [primaryObjectGuid]);

  return (
    <Fragment>
      <Box
        mr={10}
        overflowY='auto'
        p='15px 5px 0 15px'
        height='calc(100% - 139px)'
        css={scrollableContainerCss4px}
      >
        {
          R.or(documents, []).map((item: string) => (
            <Document
              document={item}
              openModal={openModal}
              closeModal={closeModal}
              key={G.getGuidFromObject(item)}
              handleDocuments={handleDocuments}
              handleDownloadOrPreviewDocument={handleDownloadOrPreviewDocument}
            />
          ))
        }
      </Box>
      <AddDocument handleDocuments={handleDocuments} />
    </Fragment>
  );
};

const toolbarSettings = {
  link: { inDropdown: true },
  list: { inDropdown: true },
  inline: { inDropdown: true },
  history: { inDropdown: true },
  textAlign: { inDropdown: true },
  options: [
    'list',
    'link',
    'emoji',
    'remove',
    'inline',
    'history',
    'fontSize',
    'blockType',
    'textAlign',
    'fontFamily',
    'colorPicker',
  ],
};

const footerBtnStyles = { width: 100, height: 25, fontSize: 13 };

const EditorComponent = ({ value, setValue, setIsEditing, additionalStyles }: Object) => {
  const [editorState, setEditorState] = useState(() => {
    if (G.isString(value)) return G.createEditorState(value);

    return value;
  });

  const ref = useRef();

  const handleSaveChanges = useCallback(() => {
    const value = G.convertHtmlToString(editorState);

    if (R.isEmpty(R.trim(R.replace(/(<([^>]+)>)/ig, '', value)))) return;

    setValue(value);
  }, [editorState]);

  useLayoutEffect(() => {
    ref.current.focus();

    setEditorState(G.moveFocusToEnd(editorState));
  }, []);

  return (
    <Fragment>
      <EditorWrapper
        width='100%'
        bg={whiteColor}
        css={additionalStyles}
      >
        <Editor
          stripPastedStyles={true}
          toolbar={toolbarSettings}
          editorState={editorState}
          onEditorStateChange={setEditorState}
          editorRef={(editorRef: Object) => ref.current = editorRef}
        />
      </EditorWrapper>
      <FormFooter2
        submitAction={handleSaveChanges}
        cancelBtnStyles={footerBtnStyles}
        submitBtnStyles={footerBtnStyles}
        boxStyles={{ mt: 15, width: 220 }}
        cancelAction={() => setIsEditing(false)}
      />
    </Fragment>
  );
};

const Note = memo(({ note, openModal, closeModal, handleNotes }: Object) => {
  const [editMode, setEditMode] = useState(false);

  const { guid, text, createdBy, createdDate, lastModifiedBy, lastModifiedDate } = note;

  return (
    <Box {...wrapperStyles} pb='0px'>
      <Flex width='100%' justifyContent='space-between'>
        <Flex alignItems='flex-start'>
          <Flex
            width={30}
            height={30}
            title={createdBy}
            border='1px solid'
            borderRadius='50%'
            justifyContent='center'
            textTransform='uppercase'
            color={greyMatterhornColor}
            borderColor={lightGreyColor}
          >
            {R.take(1, createdBy)}
          </Flex>
          <Box ml={10} fontSize={12} color={greyMatterhornColor}>
            <TextComponent maxWidth={400} display='block' title={createdBy} withEllipsis={true}>
              {createdBy}
            </TextComponent>
            <Flex mt='5px' color={darkGreyColor}>
              {G.convertDateTimeToConfigFormat(G.ifElse(G.isNotNil(lastModifiedDate), lastModifiedDate, createdDate))}
              {
                G.notEquals(createdDate, lastModifiedDate) &&
                <Flex>
                  <Span ml={10}>
                    {G.getWindowLocale('titles:edited', 'Edited')}
                  </Span>
                  <TextComponent ml='5px' maxWidth={250} display='block' withEllipsis={true} title={lastModifiedBy}>
                    ({lastModifiedBy})
                  </TextComponent>
                </Flex>
              }
            </Flex>
          </Box>
        </Flex>
        <Flex>
          <Box cursor='pointer' onClick={() => setEditMode(true)}>
            {I.pencil(darkGreyColor)}
          </Box>
          <Box
            ml={10}
            cursor='pointer'
            onClick={() =>
              renderConfirmationModal({ openModal, closeModal, action: () => handleNotes('delete', {}, guid) })
            }
          >
            {I.trash(darkGreyColor)}
          </Box>
        </Flex>
      </Flex>
      <Box my={15} color={greyMatterhornColor}>
        {
          editMode ? (
            <EditorComponent
              value={text}
              setIsEditing={setEditMode}
              setValue={(text: string) =>
                handleNotes('put', { data: R.assoc(GC.FIELD_TEXT, text, note) }, guid, () => setEditMode(false))
              }
              additionalStyles={css`
                & .DraftEditor-root {
                  overflow-y: auto;
                  max-height: 350px;

                  ${scrollableContainerCss4px}
                }
              `}
            />
          ) : (
            <Box wordBreak='break-word'>
              {parse(text)}
            </Box>
          )
        }
      </Box>
    </Box>
  );
});

const AddNote = ({ editMode, setEditMode, handleNotes, workOrderGuid }: Object) => (
  <Box my={15} px={15}>
    {
      editMode ? (
        <EditorComponent
          value=''
          editMode={true}
          setIsEditing={setEditMode}
          setValue={(text: string) =>
            handleNotes('post', { data: { text, workOrderGuid } }, null, () => setEditMode(false))
          }
          additionalStyles={css`
            & .DraftEditor-root {
              height: 100px;
              overflow-y: auto;

              ${scrollableContainerCss4px}
            }
          `}
        />
      ) : (
        <Flex
          pl={15}
          height={36}
          width='100%'
          cursor='text'
          bg={whiteColor}
          borderRadius='4px'
          border='1px solid'
          color={darkGreyColor}
          borderColor={lightGreyColor}
          onClick={() => setEditMode(true)}
        >
          {G.getWindowLocale('titles:add-note', 'Add Note')}
        </Flex>
      )
    }
  </Box>
);

const Notes = (props: Object) => {
  const { notes, openModal, closeModal, openLoader, closeLoader, setActivities, primaryObjectGuid } = props;

  const [addNoteMode, setAddNoteMode] = useState(false);

  const handleNotes = useCallback(async (
    method: string,
    options: Object,
    noteGuid: string,
    successCallback: Function,
  ) => {
    openLoader();

    const operations = {
      get: (prev: Object, data: Object) => data,
      post: (prev: Object, data: Object) => R.prepend(data, prev),
      put: (prev: Object, data: Object) => {
        const index = R.findIndex(R.propEq(G.getGuidFromObject(data), GC.FIELD_GUID), prev);

        return R.assoc(index, data, prev);
      },
      delete: (prev: Object) => {
        const index = R.findIndex(R.propEq(noteGuid, GC.FIELD_GUID), prev);

        return R.remove(index, 1, prev);
      },
    };

    const endpoints = {
      put: endpointsMap.workOrderNote,
      post: endpointsMap.workOrderNote,
      get: endpointsMap.workOrderNoteList,
      delete: endpointsMap.getWorkOrderNoteEndpoint(noteGuid),
    };

    const res = await sendRequest(
      method,
      R.prop(method, endpoints),
      {
        ...options,
        params: G.ifElse(R.equals(method, 'get'), { [GC.FIELD_WORK_ORDER_GUID]: primaryObjectGuid }, null),
      },
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      setActivities((prev: Object) => {
        const newNotes = R.call(R.prop(method, operations), R.prop(GC.FIELD_NOTES, prev), data);

        return R.assoc(GC.FIELD_NOTES, newNotes, prev);
      });

      G.callFunction(successCallback);
    } else {
      G.handleFailResponseSimple(res, 'handleNotes fail');
    }

    closeLoader();
  }, [primaryObjectGuid]);

  useEffect(() => {
    if (R.isNil(notes)) handleNotes('get');
  }, [notes, handleNotes]);

  if (R.isNil(notes)) return null;

  return (
    <Fragment>
      <Box
        mr={10}
        overflowY='auto'
        p='15px 5px 0 15px'
        css={scrollableContainerCss4px}
        height={`calc(100% - ${G.ifElse(addNoteMode, '310px', '130px')})`}
      >
        {
          notes.map((item: string) => (
            <Note
              note={item}
              openModal={openModal}
              closeModal={closeModal}
              handleNotes={handleNotes}
              key={G.getGuidFromObject(item)}
            />
          ))
        }
      </Box>
      <AddNote
        editMode={addNoteMode}
        handleNotes={handleNotes}
        setEditMode={setAddNoteMode}
        workOrderGuid={primaryObjectGuid}
      />
    </Fragment>
  );
};

const tabs = [
  { text: G.getWindowLocale('titles:notes', 'Notes') },
  { text: G.getWindowLocale('titles:documents', 'Documents') },
];

const tabsProps = {
  textColor: 'inherit',
  scrollButtons: 'auto',
  indicatorColor: blackColor,
  style: { minHeight: '26px', borderBottom: `1px solid ${lightGreyColor}` },
  TabIndicatorProps: {
    sx: {
      height: 3,
      backgroundColor: blackColor,
    },
  },
};

export const Activities = (props: Object) => {
  const { openModal, closeModal, openLoader, closeLoader, primaryObjectGuid } = props;

  const [activeTab, setActiveTab] = useState(0);
  const [activities, setActivities] = useState({});

  const commonProps = {
    openModal,
    closeModal,
    openLoader,
    closeLoader,
    setActivities,
    primaryObjectGuid,
  };

  const tabsContentMap = [
    <Notes {...commonProps} key={0} notes={R.prop(GC.FIELD_NOTES, activities)} />,
    <Documents {...commonProps} key={1} documents={R.prop(GC.FIELD_DOCUMENTS, activities)} />,
  ];

  return (
    <Box
      width={550}
      maxHeight={718}
      borderLeft='1px solid'
      borderColor={lightGreyColor}
      bg={G.getTheme('colors.whiteGrey')}
    >
      <TabsMui
        tabs={tabs}
        tabsProps={tabsProps}
        activeMuiTab={activeTab}
        setActiveMuiTab={setActiveTab}
      />
      {R.prop(activeTab, tabsContentMap)}
    </Box>
  );
};
